import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Nav, Collapse, NavItem, NavLink } from 'reactstrap';
import { Link } from 'gatsby';

import AdjustableImage from '../elements/AdjustableImage';

class TravelTipsHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isNavOpen: false
        }

        this.toggleNav = this.toggleNav.bind(this);

        this.topRef = React.createRef();
    }

    toggleNav() {
        this.setState({ isNavOpen: !this.state.isNavOpen });
    }

    render() {
        return (
            <React.Fragment>
                <div id="page-top" ref={ this.topRef } className='header-topic'>
                    <AdjustableImage image={ this.props.image } alt={ this.props.title } thumb={ this.props.thumb } />
                    <div className="header-text">
                        <h1 className="header-title">{ this.props.title }</h1>
                        <p className="header-caption">{ this.props.subtitle }</p>
                    </div>
                </div>

                <Navbar light className="bg-light border" expand="md" >
                    <div className="container">
                        <NavbarBrand className="d-block d-md-none" href="" onClick={() => { this.topRef.current.scrollIntoView() }}>
                            { this.props.title }
                        </NavbarBrand>
                        <NavbarToggler onClick={ this.toggleNav } />
                        <Collapse isOpen={ this.state.isNavOpen } navbar >
                            <Nav navbar>
                                <NavItem>
                                    <NavLink tag={ Link } to="/travel-tips/money-saving">Money Saving</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={ Link } to="/travel-tips/visas">Visas</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={ Link } to="/travel-tips/flight-deals">Flight Deals</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={ Link } to="/travel-tips/insurance">Travel Insurance</NavLink>
                                </NavItem>
                            </Nav>
                            <Nav navbar className="ms-auto">
                                <NavItem>
                                    <NavLink tag={ Link } to="/">Home</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>
            </React.Fragment>
        );
    }
}

export default TravelTipsHeader;
