import React from 'react';
import { Card, CardBody } from 'reactstrap';

import ReactCountryFlag from 'react-country-flag';
import TravelTipsHeader from './TravelTipsHeaderComponent';
import CountryVisas from './CountryVisas';

function Visas(props) {
    return (
        <React.Fragment>
            <TravelTipsHeader image="https://live.staticflickr.com/65535/50379877223_589056c2fb_o.jpg"
                thumb="https://live.staticflickr.com/65535/50379877223_6c8b6b68c3_t.jpg"
                title="Visas" subtitle="Travel Tips" />

            <div className="container">
                <div className="city-text">
                    <div className="col-12 col-lg-10 ms-auto me-auto" >
                        <p style={{ marginTop: '40px', marginBottom: '40px' }}>
                        Once you start planning a new trip, it is crucial to find out if you need to secure a visa to travel to your
                        destination and how to get it.
                        <br/><br/>
                        The regulations differ vastly from country to country about who requires a visa, what sort and for how long.
                        And no-one wants to spend hours waiting in line at government offices or worrying about whether or not the visa form was filled correctly.
                        <br/><br/>
                        Here you can find a complete set of instructions that will help you to get your visa for the marked countries:
                        </p>

                        <Card className="col-11 col-md-8 m-auto col-lg-6 m-lg-5"
                            style={{ paddingTop: '10px', borderRadius: '20px', backgroundColor: '#FAFAFA' }}>
                            <CardBody className="visas-country-list">
                                <ul>
                                    <a href="#cuba"><li style={{ marginBottom: '5px' }}>Cuba</li></a>
                                    <a href="#czechia"><li style={{ marginBottom: '5px' }}>Czech Republic</li></a>
                                    <a href="#france"><li style={{ marginBottom: '5px' }}>France</li></a>
                                    <a href="#hungary"><li style={{ marginBottom: '5px' }}>Hungary</li></a>
                                    <a href="#malta"><li style={{ marginBottom: '5px' }}>Malta</li></a>
                                    <a href="#poland"><li style={{ marginBottom: '5px' }}>Poland</li></a>
                                    <a href="#spain"><li style={{ marginBottom: '5px'}}>Spain</li></a>
                                    <a href="#uk"><li style={{ marginBottom: '5px'}}>United Kingdom</li></a>
                                    <a href="#usa"><li style={{ marginBottom: '5px' }}>United States</li></a>
                                    <a href="#vietnam"><li style={{ marginBottom: '5px' }}>Vietnam</li></a>
                                    <a href="#schengen"><li>Schengen Area</li></a>
                                </ul>
                            </CardBody>
                        </Card>

                        <p style={{ marginTop: '40px', marginBottom: '40px' }}>

                        </p>
                    </div>

                    <div className="city-topic">
                        <div className="col-12 col-lg-10 ms-auto me-auto">
                            <h1>iVisa</h1>
                            <p>
                            With iVisa, you can easily find out what type of Visa you need and how much it costs. It is a <b>trustful</b> visa processing company,
                            with a <b>100% online process</b> and with a <b>world class data security systems</b>. You can learn more about it <a href="https://www.ivisa.com/" target="_blank" rel="noopener noreferrer">here</a>
                            <br/><br/>
                            For the ones that don't accept iVisa, you can check the instructions bellow.
                            </p>
                        </div>
                    </div>

                    <div className="city-topic">
                        <div className="col-12 col-lg-10 ms-auto me-auto">
                            <h1 style={{ textAlign: 'left' }}>Visas For Countries</h1>
                            <RenderCountryVisa />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

function RenderCountryVisa() {
    return CountryVisas.map((country, pos) => {
        return (
            <div key={ pos } id={ country.id } className="country-visa-border">
                <div className="row">
                    <div className="col-12">
                        <ReactCountryFlag className="emojiFlag" countryCode={ country.image } svg
                            style={{ fontSize: '2em', lineHeight: '.15em', margin: '0px 20px 14px 6px' }} />
                        <h2 style={{ textAlign: 'left', display: 'inline-block' }}>{ country.name }</h2>
                    </div>
                </div>
                { country.desc }
            </div>
        );
    });
}

export default Visas;
