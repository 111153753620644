import React from 'react';
import { Helmet } from 'react-helmet';

function MainPageMetadata(props) {
    const currentUrl = "https://bondedworld.com" + props.pathname;

    const title = "BondedWorld - Travel Guides For Your Next Adventure";
    const image = "https://i.imgur.com/MKBDDBM.png";

    const description = "Welcome to BondedWorld! Our mission is to help connect you with the world, and by providing guidance about the various destinations around the globe, we want to make it possible for anyone, no matter where they come from or the amount of money they have, to travel the world and experience the best of what each place has to offer.";

    const hashtag = "#bondedworld";
    const facebookAppId = "987471025050482";

    return (
        <Helmet>
            <title>{ title }</title>
            <meta property="title" content={ title } />
            <meta name="description" content={ description } />
            <meta property="image" content={ image } />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={ title } />
            <meta property="og:hashtag" content={ hashtag } />
            <meta property="og:image" content={ image } />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={ currentUrl } />
            <meta property="og:site_name" content={ title } />
            <meta property="og:description" content={ description } />
            <meta property="fb:app_id" content={ facebookAppId } />
        </Helmet>
    )
}

export default MainPageMetadata;
