import React from 'react';

const CountryVisas = [
    {
        name: "Cuba",
        image: "CU",
        id: "cuba",
        desc: <p>
        The required tourist visa, also known as tourist card, allows the holder to stay in Cuba for 30 days and is valid for a single entry.
        You must have a valid passport for the length of the proposed duration of the visit, as well as proof of a confirmed return flight and booked accommodation.
        <br/><br/>
        Some airline companies (mostly from USA) allow you to get the tourist card at the check-in balcony, with prices that can vary from
        50$ (ex. JetBlue) to 100$ (ex. American Airlines). Of course, you need to travel with those companies in order to be possible to get the Visa this way.
        <br/><br/>
        Citizens of the following 18 countries <b>do not require a tourist card or a visa</b> to visit Cuba. Still, the amount of time they can stay varies per country:
        <br/><br/>
        <ul>
            <li>Up to 90 days: Benin, Bosnia and Herzegovina, China, Malaysia, Montenegro, North Macedonia and Serbia;</li>
            <li>Up to 60 days: Grenada and Saint Vincent and the Grenadines;</li>
            <li>Up to 30 days: Antigua and Barbuda, Belarus, Mongolia, Namibia, Saint Kitts and Nevis, Saint Lucia and Singapore;</li>
            <li>Up to 28 days: Barbados and Dominica.</li>
        </ul>
        Citizens of the USA are subjected to the Cuban assets control regulations enforced by the office of <a href="http://www.treasury.gov/resource-center/sanctions/Programs/pages/cuba.aspx" target="_blank" rel="noopener noreferrer">Foiregn Assets Control</a>.
        For those citizens, and everyone else going from USA to Cuba, travel for touristic purposes is still prohibited. That said, it's possible to visit Cuba as an American if you're
        visiting for one of the 12 specified reasons described in the link before. If your visit is merely for travelling, we recommend you to choose <b>support for the Cuban people</b>.
        <br/><br/>
        You can get your tourist card by going to the embassy of Cuba in your country or by post. You can use this <a href="http://misiones.minrex.gob.cu/sites/default/files/formularios/editorreinounido/application_form_for_tourist_card.pdf" target="_blank" rel="noopener noreferrer">form</a> to request your visa.
        If you are from the UK <b>you must</b> use this format form. If not you can obtain application forms for tourist visa cards from certain tour operatours and travel agents of from the consulate/embassy.
        <br></br>
        The tourist card is valid for one entry of up to <b>30 days</b> within 180 days of issue and can be extended in Cuba for another 30 days.
        <br/><br/>
        Attached to the tourist card, all visitors to Cuba are required to <b>prove they have valid travel insurance</b> covering medical expenses from
        approved foreign companies during their period of stay (again some flight companies give you the necessary papers for this). Take into account that US travel insurance policies are not accepted.
        <br></br>
        If you are travelling with children it may be required to show proof of parental rights or guardianship.
        <br/><br/>
        Cuba has eradicated Yellow Fever, but the main mosquito vector is still present on the country.
        As such, any traveler (at least 9 months older) coming from or living in a country where there is risk of Yellow Fever transmission must present an Yellow Fever vaccionation certificate at the entrance.
        Consult <a href="https://www.iamat.org/country/cuba/risk/yellow-fever" target="_blank" rel="noopener noreferrer">here</a> for more information on which countries this applies.
        <br/><br/>
        Bringing pets to Cuba can be also difficult. It is possible, but it will take you some time and money. You need an
        international veterinary certificate, completed no more than 10 days before travel to Cuba, along with proof of a rabies and
        distemper vaccination admininstrated at least 30 days before entry.
        </p>
    },
    {
        name: "Czech Republic",
        image: "CZ",
        id: "czechia",
        desc:<p>
        Consult the <a href="#schengen">Schengen Area</a> for more information...
        </p>
    },
    {
        name: "France",
        image: "FR",
        id: "france",
        desc:<p>
        Consult the <a href="#schengen">Schengen Area</a> for more information...
        </p>
    },
    {
        name: "Hungary",
        image: "HU",
        id: "hungary",
        desc:<p>
        Consult the <a href="#schengen">Schengen Area</a> for more information...
        </p>
    },
    {
        name: "Malta",
        image: "MT",
        id: "malta",
        desc:<p>
        Consult the <a href="#schengen">Schengen Area</a> for more information...
        </p>
    },
    {
        name: "Poland",
        image: "PL",
        id: "poland",
        desc:<p>
        Consult the <a href="#schengen">Schengen Area</a> for more information...
        </p>
    },
    {
        name: "Spain",
        image: "ES",
        id: "spain",
        desc: <p>
        Consult the <a href="#schengen">Schengen Area</a> for more information...
        </p>
    },
    {
        name: "United Kingdom",
        image: "GB",
        id: "uk",
        desc: <p>
        For now, if you're inside the Schengen Area, you don't need a Visa to travel to the United Kingdom. However the rules will change in the future. For those outside consult the <a href="#schengen">Schengen Area</a> for more information...
        </p>
    },
    {
        name: "United States",
        image: "US",
        id: "usa",
        desc: <p>
            The U.S. Department of State issues visas to foreign nationals traveling to the United States through its embassies or
            consulat. However, you do not need a visa for your business meeting or for vacation if you are a citizen of any of the
            38 countries participating in the <b>Visa Waiver Program</b>.
            <br/><br/>
            The countries eligible for this program are: Andorra, Australia, Austria, Belgium, Brunei, Chile, Czech Republic, Denmark,
            Estonia, Finland, France, Germany, Greece, Hungary, Iceland, Ireland, Italy, Japan, Republic of Korea, Latvia,
            Liechtenstein, Lithuania, Luxembourg, Malta, Monaco, Netherlands, New Zealand, Norway, Portugal, San Marino, Singapore,
            Slovakia, Slovenia, Spain, Sweden, Switzerland, Taiwan (this is consistent with the United States’ one-China policy,
            under which the United States has maintained unofficial relations with Taiwan since 1979), United Kingdom (England,
            Scotland, Wales, Northern Ireland, the Channel Islands and the Isle of Man).
            <br/><br/>
            The tourists of that group of countries only need to request an <b>ESTA</b> (Electronic System for Travel Authorization).
            This document is an automated system that assists in determining eligibility to travel to the United States under the
            Visa Waiver Program. You can apply for your ESTA <a href="https://www.cbp.gov/travel/international-visitors/esta" target="_blank" rel="noopener noreferrer">here</a> for a fee of around 15$.
            <br/><br/>
            If you don't see your country listed, you will need a nonimmigrant visa to visit the U.S.
            First of all you need to check which visa you might need for your visit. Usually, most business and tourism travelers use B-1 and B-2 visitor visas.
            <br/><br/>
            <ul>
            <li><b>B-1 visa classification</b> is applied for business travelers that are related with business associates, that are attending a conference, settling an estate, or negotiating a contract;</li>
            <li><b>B-2 visa classification</b> is for tourists on vacation (probably your case) and people coming for medical treatment, a social event, or participation in amateur contests for no pay.</li>
        </ul>
        In addiction to it, there also exists the transit visas. <b>Transit Visa C</b> is for those traveling through the U.S. to another
        country and stopping briefly in the U.S. as part of their travel to the next foreign destination. <b>Transit C-1</b>, <b>D</b>, and <b>C-1/D
        visas</b> are for crewmembers of sea vessels or international airlines traveling to the U.S.
        <br/><br/>
        After checking what you need you can apply for said Visa. This process depends on the U.S. Embassy or Consulate where you apply.
        It is important to follow the specific instructions from the country <b>you’re in</b>.
        <br/><br/>
        To complete the process in general, you will need to:
        <ul>
            <li>Complete online form <a href="https://ceac.state.gov/genniv/" target="_blank" rel="noopener noreferrer">DS-160</a>, the nonimmigrant visa application form;</li>
            <li>A photo, generally 3x4;</li>
            <li>Pay the <a href="https://travel.state.gov/content/travel/en/us-visas/visa-information-resources/fees/fees-reciprocity-tables.html" target="_blank" rel="noopener noreferrer">visa application fee</a>;</li>
            <li>In some cases you will need to schedule an interview.</li>
        </ul>
        </p>
    },
    {
        name: "Vietnam",
        image: "VN",
        id: "vietnam",
        desc: <p>
        Vietnam has visa exemptions to travelers from 24 countries, and e-Visas to travelers from 80 countries. You can easily apply for a visa on arrival online or in person at a Vietnamese embassy or consulate.
        <br/><br/>
        The full list of countries with visa exemptions can be found <a href="http://lanhsuvietnam.gov.vn/Lists/BaiViet/B%C3%A0i%20vi%E1%BA%BFt/DispForm.aspx?List=dc7c7d75-6a32-4215-afeb-47d4bee70eee&ID=306" target="_blank" rel="noopener noreferrer">here</a>. You can also find on this link all the visa exemptions for diplomatic and other passports:
        <br/><br/>
        <ul>
            <li>Less than 90 days: Chile;</li>
            <li>Less than 30 days: Thailand, Malaysia, Singapore, Indonesia, Cambodia, Lao, South Korea, Japan, Kyrgystan, Russia, Sweden, Denmark, Finland, Norway, United Kingdom, France, Germany, Spain, Italy and Belarus;</li>
            <li>Less than 21 days: Philippines;</li>
            <li>Less than 14 days: Brunei.</li>
        </ul>
        Partners or children of Vietnamese citizens are allowed to stay in the country without a visa for six months and must show papers verifying their eligibility. For full requirements, please visit the <a href="http://mienthithucvk.mofa.gov.vn/" target="_blank" rel="noopener noreferrer">foreign affairs website</a>.
        <br/><br/>
        For the countries that aren't included on the exemption list, Vietnam's e-Visa is now available to citizens of 80 countries:
        <br/><br/>
        Andorra, Argentina, Armenia, Australia, Austria, Azerbaijan, Belarus, Belgium, Bosnia and Herzegovina, Brazil, Brunei, Bulgaria, Canada, Colombia, Croatia, Cuba, Cyprus, Czech Republic, Chile, China (including Hong Kong and Macau passports), Denmark, Estonia, Fiji, Finland, France, Georgia, Germany, Greece, Hungary, Iceland, India, Ireland, Italy, Japan, Kazakhstan, Latvia, Liechtenstein, Lithuania, Luxembourg, Malta, Marshall Islands, Mexico, Micronesia, Moldova, Monaco, Montenegro, Mongolia, Myanmar, Nauru, Netherlands, New Zealand, North Macedonia, Norway, Palau, Panama, Papua New Guinea, Peru, Poland, Portugal, Philippines, Qatar, Romania, Russia, Salomon Islands, San Marino, Serbia, Slovakia, Slovenia, South Korea, Spain, Sweden, Switzerland, Timor Leste, United Arab Emirates, United Kingdom, United States of America, Uruguay, Vanuatu, Venezuela, and Western Samoa.
        <br/><br/>
        Vietnam's e-Visa is one type of visa made available by the Vietnam Immigration Department to foreigners entering the country. This is valid for 30 days, and admits a single entry only. The e-Visa costs 25 USD for card payments.
        It requires three full working days to process. You can enter in Vietnam with this document at any of the country's eight international airports, as well as the 14 land crossings and seven seaports.
        Your visa is valid from the start to the end dates specified. You can enter the country any time within those dates, but <b>only once</b>.
        <br/><br/>
        In order to apply to E-Visa, you can do so at the <a href="https://evisa.xuatnhapcanh.gov.vn/web/guest/trang-chu-ttdt" target="_blank" rel="noopener noreferrer">official website</a>.
        For any questions you have about filling the forms you can consult the <a href="https://evisa.xuatnhapcanh.gov.vn/web/guest/faq" target="_blank" rel="noopener noreferrer">FAQ's Page</a>. At some point you will be asked for a "<i>temporary address</i>" in Vietnam, don't worry if you don't have one, you can leave this empty.
        <br/><br/>
        After applying for your e-Visa online, you’ll need to wait around three working days for your visa to be released. After three days, you may search for your visa on the same website. Make sure that you take some copies with you on your trip.
        When you arrive to Vietnam, don’t forget that travellers with an e-Visa can go <b>directly</b> to immigration counters at their point of entry and do not need to queue at Visa on Arrival counters.
        <br/><br/>
        <h5>Visa On Arrival</h5>
        If you are organizing a multiple-entry visit or a stay of more than 30 days, you will need to request for a visa on arrival. For that you'll need:
        <br/><br/>
        <ul>
            <li>4x6 passport photo with a white background and no glasses;</li>
            <li>Filled-out visa application form;</li>
            <li>Passport or substitute ID valid for six months from the date you plan to enter Vietnam;</li>
            <li>Payment (25 USD to 50 USD) for visa fees;</li>
            <li>Letter of Approval from a Vietnamese embassy or consulate (if you are picking up your visa at the airport).</li>
        </ul>
        If you live close to a Vietnamese embassy or consulate, you can submit your photo, application form, passport, and visa fee in person.
        <br/><br/>
        If you are unable to reach a Vietnamese embassy, or are short on time, there are trusted services online who can provide you a valid <b>Letter of Approval</b> for a fee. Take this letter and together with a visa application form and your other documents you will be able to get the Visa on Arrival at the airport when you land.
        <br/><br/>
        All nationalities traveling directly to Phu Quoc Island can visit the island for 30 days <b>visa-free</b>. To do this you will need to show an outbound ticket on arrival in Vietnam.
        </p>
    },
    {
        name: "Schengen Area",
        image: "EU",
        id: "schengen",
        desc:  <p>
        The Schengen Area comprises 26 European Countries that function as a single jurisdiction for international travel, and you can transit freely between those countries with the same visa policy.
        <br/><br/>
        Currently, those are the 26 coutries that belong to the Schengen Area:
        <br/><br/>
        Austria, Belgium, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Iceland, Italy, Latvia, Liechtenstein, Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Slovakia, Slovenia, Spain, Sweden, Switzerland.
        <br/><br/>
        The following 3 states, although not belonging to the Schengen Area, have open borders with it: Monaco, San Marino and Vatican City.
        <br/><br/>
        To get a Visa to enter the Schengen Area, there are different rules depending the part of the world you are travelling from:
        <br/><br/>
            <ul>
                <li>EU Citizens: If you are from any country within this zone, you can visit any other country using only your passport or I.D.;</li>
                <li>US Citizens: American citizens traveling to the Schegen Area for less than 90 days do not need a visa. However, they will need a valid passport for at least six months beyond their stay. Immigration officers may also ask citizens to show enough funds for their stay and a return airline ticket;</li>
                <li>Australian Citizens: Australian citizens visiting any European country in the Schengen Convention for less than 90 days do not require a visa. Passports need to have at least six-month validity from the planned date of return;</li>
                <li>Visa-Free Countries: Andorra, Antigua and Bermuda, Argentina, Bahamas, Barbados, Bolivia, Bosnia, Brunei, Canada, Chile, Costa Rica, El Salvador, Guatemala, Honduras, Hong Kong, Israel, Japan, Korea, Macao, Malaysia, Mauritius, Mexico, Monaco, Montenegro, Nicaragua, New Zealand, Panama, Paraguay, Saint Kitts and Nevis, San Marino, Serbia, Seychelles, Singapore, Taiwan, Uruguay and Venezuela do not require a visa for visits lasting less than 90 days, but require a valid passport to enter.</li>
            </ul>
        For citizens of countries not previously mentioned, we recommend going to the Embassy of the country you pretend to visit, or visit the respective website for more information.
        </p>
    }
];

export default CountryVisas;
