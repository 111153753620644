import React from 'react';
import Visas from '../../components/travelTips/VisasComponent';
import Footer from '../../components/FooterComponent';

import MainPageMetadata from '../../metadata/MainPageMetadata';

function VisasPage(props) {
    return (
        <div>
            <MainPageMetadata pathname={ props.location.pathname } />
            <Visas />
            <Footer />
        </div>
    )
}

export default VisasPage;
