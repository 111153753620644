import React from 'react';

function AdjustableImage (props) {
    const [ isLoaded, setIsLoaded ] = React.useState(false);
    const imgRef = React.useRef();

    React.useEffect(() => {
        if (imgRef.current.complete) setIsLoaded(true);
    }, [imgRef, setIsLoaded]);

    return (
        <div className="adjustable-height">
            <img src={ props.thumb } className="header-topic-img blur absolute" width="100%" height="100%" alt={ props.name }
                style={{ visibility: isLoaded ? 'hidden' : 'visible' }}/>
            <img ref={ imgRef } 
                onLoad={() => setIsLoaded(true) } className="header-topic-img full absolute" 
                style={{ visibility: isLoaded ? 'visible' : 'hidden' }} width="100%" height="100%" alt={ props.name } 
                src={ props.image } />
        </div>
    );
}

export default AdjustableImage;
